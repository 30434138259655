.custom-container {
  margin-left: 0;
  margin-right: 0;
}

.App {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Animation for the logo */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Redefining App-header for consistency with App */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.App-link {
  color: #61dafb;
}

/* Keyframes for logo spinning animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional styles for buttons and form elements */
button, .form-control {
  margin: 10px 0; /* Margin for spacing */
}

/* Card styles for uniform appearance */
.card {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent */
  border: none;
  color: white;
}
